import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import Layout from '../components/layout';
import NewsItem from '../components/news-item';
import '../styles/start.scss';
import { isMobile } from '../utils/helper';
import { Download } from '@mui/icons-material';
import Widget from '../components/widget';

interface INode {
  fields: {
    slug: string
  },
  frontmatter: {
    date: string,
    slug: string,
    title: string
  },
  html: string
};

type StartPageProps = {
  data: {
    allMarkdownRemark: {
      nodes: Array<INode>
    }
  }
};

export default function StartPage({ data }: StartPageProps) {
  const newsList: Array<INode> = data.allMarkdownRemark.nodes;

  const { t } = useI18next();

  const handleDownload = () => {
    const fileUrl = '/magazine/2024/Heft_Portugiesen_Online_2024_07.pdf';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'Heft_Portugiesen_Online_2024_07.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const targetDate: Date = new Date('2024-11-01');
  const today: Date = new Date();

  const isBeforeTargetDate: boolean = today <= targetDate;

  return (
    <Layout>
      <div className="welcome-banner">
        <div className="grid-item-left"></div>
        <div className="grid-item-middle">
          <div className="welcome-container">
            <Typography className="welcome-title" variant="h1">
              <Box fontStyle="italic">{t("welcome")}</Box>
            </Typography>
            <div className="welcome-blockquote-container">
              <blockquote className="welcome-blockqoute">
                <Typography className="welcome-blockquote-text" component="p">
                  {/*t("welcomeText")*/}
                </Typography>
              </blockquote>
            </div>
          </div>
        </div>
        <div className="grid-item-middle-right">
          <div className="welcome-image1"></div>
        </div>
      </div>
      <div className="utils-margin-bottom-20"></div>
      {isBeforeTargetDate && (
        <div className={isMobile() ? '' : 'center'}>
          <img className="responsive-image" src="/img/castanhas.png"/>
        </div>
      )}
      <div className="seperator-container">
        <div className="separator">
          <Typography variant="h6">{t("info")}</Typography>
        </div>
      </div>
      <div className="utils-margin-bottom-20"></div>
      <div className={isMobile() ? '' : 'center'}>
        <div dangerouslySetInnerHTML={{__html: t("infoText")}}/>
      </div>
      
      <div className="seperator-container">
        <div className="separator">
          <Typography variant="h6">{t("news")}</Typography>
        </div>
      </div>
      <div className="utils-margin-bottom-20"></div>
      <div className="download-container">
        <Button className="download-button" variant="contained" startIcon={<Download/>} onClick={handleDownload}>
          {t("downloadMagazine")}
        </Button>
      </div>
      <div className="utils-margin-bottom-20"></div>
      <Grid container>
        {newsList.map((news, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <NewsItem title={news.frontmatter.title} date={news.frontmatter.date} link={news.fields.slug} preview={news.html}/>
          </Grid>
        ))}
      </Grid>
      <div className="seperator-container">
        <div className="separator">
          <Typography variant="h6">{t("lastNextGame")}</Typography>
        </div>
      </div>
      <div className="utils-margin-bottom-20"></div>
      <Widget id="12eb052f-8732-4f74-8ca5-701df52f9c84" type="club-matches"/>
    </Layout>
  );
}

export const pageQuery = graphql`
  query getNewsByLocale($locale: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/news/" }, fields: { locale: { eq: $locale } } },
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 10
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD.MM.YYYY")
          title
        }
        html
      }
    }
  }
`;